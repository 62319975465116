<template>
  <div class="home">
    <div class="header">
      <div class="h_t">
        <span class="zh">惠城区疫情防控指挥中心</span>
        <span class="en">HuiCheng District Epidemic Prevention and Control Command Center</span>
      </div>
      <span class="back" @click="$router.back()">返回</span>
    </div>
    <div class="time">{{areaname}} {{time}}</div>
    <div style="display: flex;justify-content: center;align-items: center;height: calc(100vh - 200px);">
      <div class="process_bg">
        <MessageCon v-for="(item, i) in list" :key="i" 
          :padding="item.padding" 
          :width="item.width" 
          :height="item.height" 
          :text="item.text" 
          :pos="item.pos" 
          :topNum="item.topNum"
          :fontSize="item.fontSize"
          :bottomNum="item.bottomNum" 
          :color="item.color"
          :styleObj="item.style"
          :flex="item.flex"
          :paddingtext="item.paddingtext" 
          @move-handle="moveHandle($event, item)"
          @click.native="handleclick(item)"
          @leave-handle="leaveHandle" />
          <span class="label" v-for="(l, aindex) in labelList" :key="'ps' + aindex" :style="{left: l.pos.x + 'px', top: l.pos.y + 'px', width: l.width + 'px'}">
            {{l.name}}
          </span>
          <div class="number" v-for="(l, bindex) in numberList" :key="'ts' + bindex" :style="{left: l.pos.x + 'px', top: l.pos.y + 'px', width: l.width + 'px'}">
            {{l.value}}
          </div>
      </div>
    </div>
    <el-dialog  :visible.sync="dialogVisible">
      <CustomCon class="tip2" :showTitle="false"   style="padding-top: 40px;">
        <div class="new" v-html="curTip">
          
        </div>
      </CustomCon>
    </el-dialog>
    <CustomCon class="tip" :style="{left: tipPos.x + 'px', top: tipPos.y + 'px'}" :showTitle="false" style="width: 300px">
      <div v-html="curTip">
      </div>
    </CustomCon>
  </div>
</template>

<script>
import CustomCon from '../components/custom-con.vue'
import {formatDate} from '../utils/formatDate'
import MessageCon from './components/messageCon.vue'
export default {
  name: 'HomeView',
  components: {
    MessageCon,
    CustomCon
  },
  data() {
    return {
      tipPos: {x: -100000, y: 0},
      time: '',
      timer: null,
      list: [
        {
          width: '110',
          height: '143',
          text: '医院核酸检测报告呈现阳性',
          pos: {x: '58', y: '160'},
          topNum: '1-1',
          color: '#fff100',
          fontSize: 20,
          paddingtext: '10px 0 0 0'
        },
        {
          width: '110',
          height: '143',
          text: '登记、隔离转运、医疗救治、消杀',
          pos: {x: '58', y: '345'},
          topNum: '',
          bottomNum: '1',
          paddingtext: '10px 0 0 0',
          color: '#00eba0'
        },
        {
          width: '97',
          height: '143',
          text: '区卫生健康局',
          pos: {x: '224', y: '160'},
          fontSize: 28,
          padding: '10px 10px 10px 10px',
          topNum: '',
          color: '#fff100'
        },
        {
          width: '97',
          height: '143',
          text: '市疾控中心',
          pos: {x: '377', y: '160'},
          topNum: '3-1',
          bottomNum: '3',
          fontSize: 24,
          paddingtext: '12px 0 0 0',
          color: '#fff100'
        },
        {
          width: '97',
          height: '143',
          text: '派遣专业人员进行初步流调',
          pos: {x: '377', y: '345'},
          topNum: '',
          fontSize: 20,
          paddingtext: '8px 0 0 0',
          color: '#02cb94'
        },
        {
          width: '98',
          height: '143',
          text: '核酸复核',
          pos: {x: '529', y: '160'},
          fontSize: 28,
          topNum: '',
          padding: '30px 10px 10px 10px',
          color: '#fff100'
        },
        {
          width: '98',
          height: '58',
          text: '确诊',
          fontSize: 26,
          pos: {x: '682', y: '160'},
          topNum: '',
          color: '#cd5359'
        },
        {
          width: '160',
          height: '110',
          text: '进行深入流调、终末消毒、环境监测.',
          pos: {x: '650', y: '10'},
          topNum: '3-2',
          color: '#00eba0'
        },
        {
          width: '98',
          height: '58',
          text: '排除',
          fontSize: 26,
          pos: {x: '682', y: '247'},
          topNum: '',
          color: '#00eba0'
        },
        {
          width: '98',
          height: '143',
          text: '修订报告<br>通报区政府、区卫生健康局',
          pos: {x: '682', y: '343'},
          topNum: '',
          padding: '18px 5px 5px 5px',
          fontSize: 20,
          color: '#00eba0'
        },
        {
          width: '96',
          height: '329',
          text: '启动疫情防控应急处置领导小组',
          pos: {x: '837', y: '160'},
          topNum: '2-1',
          bottomNum: '2',
          fontSize: 26,
          paddingtext: '8px 0 0 0',
          color: '#fff100'
        },
        {
          width: '52',
          height: '143',
          text: '区防控办',
          pos: {x: '991', y: '160'},
          fontSize: 24,
          topNum: '',
          padding: '5px 10px 5px 10px',
          color: '#fff100'
        },
        {
          width: '52',
          height: '141',
          text: '逐级上报',
          fontSize: 24,
          pos: {x: '991', y: '-20'},
          topNum: '',
          padding: '5px 10px 5px 10px',
          color: '#00eba0'
        },
        {
          width: '70',
          height: '144',
          fontSize: 18,
          text: '根据疫情科学 划分风险等级',
          pos: {x: '982', y: '344'},
          topNum: '',
          padding: '0px 10px 10px 10px',
          color: '#00eba0'
        },
        {
          width: '70',
          height: '185',
          text: '根据流调情况和风险等级实行社会面管控措施',
          pos: {x: '982', y: '530'},
          topNum: '',
          padding: '18px 5px 10px 5px',
          color: '#00eba0'
        },
        {
          width: '470',
          height: '123',
          text: '完成上报程序，经市新冠疫情防控工作领导小组审定后，由市新冠疫情防控领导小组或领导小组指定部门统一发布。',
          pos: {x: '1099', y: '0'},
          topNum: '4-1',
          bottomNum: '4',
          color: '#fff100',
          paddingtext: '8px 0 0 0',
          
        },
        {
          width: '95',
          height: '280',
          text: '联防联控，划定防控区域，同时启动相应应急预案。',
          pos: {x: '1099', y: '164'},
          topNum: '5-1',
          bottomNum: '5',
          fontSize: 20,
          color: '#fff100',
        },
        {
          width: '88',
          height: '48',
          text: '人员流动管理',
          pos: {x: '1266', y: '158'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        },   
        {
          width: '88',
          height: '48',
          text: '居家隔离、减少外出',
          pos: {x: '1381', y: '158'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        },   
        {
          width: '88',
          height: '48',
          text: '社区管理',
          pos: {x: '1266', y: '209'},
          topNum: '',
          padding: '10px',
          color: '#00eba0'
        },  
        {
          width: '88',
          height: '48',
          text: '设置卡口、物资保障',
          pos: {x: '1381', y: '209'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        }, 
        {
          width: '88',
          height: '48',
          text: '学校管理',
          pos: {x: '1266', y: '260'},
          topNum: '',
          padding: '10px',
          color: '#00eba0'
        },  
        {
          width: '88',
          height: '48',
          text: '停课',
          pos: {x: '1381', y: '260'},
          topNum: '',
          padding: '10px',
          color: '#00eba0'
        },  
        {
          width: '88',
          height: '48',
          text: '公共场所管理',
          pos: {x: '1266', y: '311'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        },  
        {
          width: '88',
          height: '48',
          text: '关停限流',
          pos: {x: '1381', y: '311'},
          topNum: '',
          padding: '10px',
          color: '#00eba0'
        }, 
        {
          width: '88',
          height: '48',
          text: '医疗机构管理',
          pos: {x: '1266', y: '361'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        }, 
        {
          width: '88',
          height: '48',
          text: '加强管理、排查隐患',
          pos: {x: '1381', y: '361'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        }, 
        {
          width: '88',
          height: '48',
          text: '其他区域管理',
          pos: {x: '1266', y: '412'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        }, 
        {
          width: '88',
          height: '48',
          text: '落实防控措施',
          pos: {x: '1381', y: '412'},
          topNum: '',
          padding: '0',
          color: '#00eba0'
        }, 
        {
          width: '44',
          height: '280  ',
          text: '疫情得到控制，结束应急处置',
          pos: {x: '1525', y: '160'},
          topNum: '',
          color: '#fff100'
        },
        {
          width: '95',
          height: '170',
          text: '现场调查 人员管控',
          pos: {x: '1099', y: '497'},
          topNum: '',
          fontSize: 25,
          bottomNum: '6',
          padding: '6px 10px 10px 10px',
          color: '#fff100'
        },
        {
          width: '200',
          height: '57',
          text: '病例集中隔离救治',
          pos: {x: '1267', y: '487'},
          topNum: '',
          padding: '15px',
          color: '#00eba0'
        },
        {
          width: '200',
          height: '57',
          text: '密接及次密接隔离',
          padding: '15px',
          pos: {x: '1267', y: '555'},
          topNum: '',
          color: '#00eba0'
        },
        {
          width: '200',
          height: '57',
          text: '一般接触者：健康风险告知、核酸检测',
          pos: {x: '1267', y: '623'},
          topNum: '',
          
          padding: '5px 10px 10px 10px',
          color: '#00eba0'
        },
        {
          width: '43',
          height: '194',
          text: '结束人员管控 ',
          pos: {x: '1526', y: '488'},
          topNum: '',
          padding: '30px 10px 10px 10px',
          color: '#fff100'
        },
        {
          width: '92',
          height: '276',
          text: '评判疫情防控效果宣布疫情处置结束解除风险等级 ',
          pos: {x: '1626', y: '166'},
          topNum: '',
          fontSize: 23,
          color: '#fff100',
          flex: 'flex'
        },
        {
          width: '92',
          height: '276',
          text: '疫情结束 ',
          fontSize: 30,
          pos: {x: '1775', y: '166'},
          topNum: '',
          color: '#fff100',
          flex: 'flex'
        },
      ],
      labelList: [
        {
          name: '报告',
          pos: {x: '180', y: '246'},
        },
        {
          name: '网络直报',
          pos: {x: '237', y: '87'},
          width: '70'
        },
        {
          name: '上报',
          pos: {x: '330', y: '246'},
        },
        {
          name: '上报',
          pos: {x: '562', y: '542'},
        },
        {
          name: '启动应急指挥体系',
          pos: {x: '783', y: '194'},
        },
        {
          name: '通报',
          pos: {x: '870', y: '111'},
        },
      ],
      numberList: [
        {
          value: '5-2',
          pos: {x: '1200', y: '171'},
        },
        {
          value: '5-3/5-4',
          pos: {x: '1192', y: '412'},
          width: '60'
        },
        {
          value: '6-1',
          pos: {x: '1200', y: '570'},
        },
        {
          value: '6-2',
          pos: {x: '1200', y: '635'},
        }
      ],
      curTip: '',
      areaname: '',
      dialogVisible:false
    }
  },
  mounted() {
    this.getProcessLabel()
    this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    this.timer = setInterval(() => {
      this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    }, 1000);
    this.areaname = this.$route.query.areaName
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moveHandle(e, item) {
      let text = item.tip;
      if(!text) return;
      this.curTip = text;
      //this.tipPos.x = e.screenX + 30;
      //this.tipPos.y = e.screenY - 150;
      if (e.screenX + 300 > 1920) {
        this.tipPos.x = e.pageX + 15 - 350;
        this.tipPos.y = e.pageY - 30;
      }else{
        this.tipPos.x = e.pageX + 15;
        this.tipPos.y = e.pageY - 30;
      }
      
    },
    leaveHandle() {
      this.tipPos.x = -100000;
    },
    getProcessLabel() {
      this.$axios({
        url: 'http://test.tianjingcn.cn/api/tree',
        method: 'post',
        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
      ).then((res) => {
          this.labelMap = res.data.data;
          this.list[0].tip = this.labelMap[0].A1
          this.list[1].tip = this.labelMap[0].A2
          this.list[2].tip = this.labelMap[0].A3
          this.list[3].tip = this.labelMap[0].A4
          this.list[4].tip = this.labelMap[0].A5
          this.list[5].tip = this.labelMap[0].A6
          this.list[7].tip = this.labelMap[0].B1
          this.list[9].tip = this.labelMap[0].B2
          this.list[10].tip = this.labelMap[0].B3
          this.list[11].tip = this.labelMap[0].B5
          this.list[12].tip = this.labelMap[0].B4
          this.list[13].tip = this.labelMap[0].B6
          this.list[14].tip = this.labelMap[0].B7
          this.list[15].tip = this.labelMap[0].C1
          this.list[16].tip = this.labelMap[0].D1
          this.list[17].tip = this.labelMap[0].D2
          this.list[18].tip = this.labelMap[0].D8
          this.list[19].tip = this.labelMap[0].D3
          this.list[20].tip = this.labelMap[0].D9
          this.list[21].tip = this.labelMap[0].D4
          this.list[22].tip = this.labelMap[0].D10
          this.list[23].tip = this.labelMap[0].D5
          this.list[24].tip = this.labelMap[0].D11
          this.list[25].tip = this.labelMap[0].D6
          this.list[26].tip = this.labelMap[0].D12
          this.list[27].tip = this.labelMap[0].D7
          this.list[28].tip = this.labelMap[0].D13
          this.list[29].tip = this.labelMap[0].D14
          this.list[30].tip = this.labelMap[0].E1
          this.list[31].tip = this.labelMap[0].E2
          this.list[32].tip = this.labelMap[0].E3
          this.list[33].tip = this.labelMap[0].E4
          this.list[34].tip = this.labelMap[0].E5
          this.list[35].tip = this.labelMap[0].C2
          this.list[36].tip = this.labelMap[0].C3
      })
    },
    handleclick(item) {
      console.log(item);
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  background-image: url("../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  color: #00faf9;
  .header {
    height: 20px;
    background-image: url('../assets/img/h1.png');
    background-size: 100% 100%;
    position: relative;
    .h_t {
      height: 90px;
      width: 600px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('../assets/img/t1.png');
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .zh {
        font-size: 33px;
        font-weight: bold;
      }
      .en {
        font-size: 14px;
      }
    }
    .back {
      border: 1px solid #1f4170;
      background-color: rgba($color: #000000, $alpha: 0.3);
      padding: 5px 10px;
      position: absolute;
      right: 20px;
      top: 30px;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .time {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    height: 30px;
    margin-top: 80px;
  }
  .process_bg {
    margin-top: 40px;
    background-image: url('../assets/img/images/ps_02.png');
    background-repeat: no-repeat;
    background-size: 1900px 737px;
    //height: calc(100vh - 200px);
    position: relative;
    height: 737px;
    width: 2000px;
    .label {
      position: absolute;
      width: 50px;
    }
    .number {
      position: absolute;
      border: 1px solid #fff;
      background-color: #1f4170;
      color: #fff;
      line-height: 24px;
      border-radius: 24px;
      text-align: center;
      width: 50px;
    }
  }
}

.tip {
  padding: 10px;
  background-color: #004259;
  position: absolute;
  left: -1000px;
}
.tip2 {
  padding: 20px;
  background-color: #004259;
  font-size: 24px;
  //height: 500px;
}

::v-deep .el-dialog__header {
  padding: 0;
  
}
::v-deep .el-dialog__body {
  padding: 0;
  /*background-color: rgba(3, 27, 63, 0.8);*/
  background-color: #004259;
  color:#00faf9;
}
::v-deep .el-dialog__headerbtn {
  z-index: 9999;
}
</style>
