<template>
  <div class="message" @mousemove="moveHandle" @mouseleave="leaveHandle" :class="flex" :style="{padding: padding, fontWeight: '700', width: width + 'px', height: height + 'px', left: pos.x + 'px', top: pos.y + 'px', borderColor: color, backgroundColor: color + '30'}">
    <div class="top" :style="styleObj" v-if="topNum">{{topNum}}</div>
    <div class="center" :style="{color: color, padding: paddingtext, fontSize: fontSize + 'px'}" v-html="text"></div>
    <div class="btm" v-if="bottomNum">
      <div class="num">{{bottomNum}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeassgeCon",
  props: {
    width: {
      type: [Number, String],
      default: 0
    },
    height: {
      type: [Number, String],
      default: 0
    },
    text: String,
    styleObj: Object,
    pos: {
      type: Object,
      default: () => ({x: 0, y: 0})
    },
    color: {
      type: String,
      default: '#fff'
    },
    fontSize:{
      type: Number,
      default: 16
    },
    padding: {
      type: String,
      default: '10px'
    },
    paddingtext: {
      type: String,
      default: '0px'
    },
    topNum: String,
    bottomNum: String,
    flex: String
  },
  methods: {
    moveHandle(e) {
      this.$emit('move-handle', e)
    },
    leaveHandle(e) {
      this.$emit('leave-handle', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  position: absolute;
  border: 1px solid;
  box-sizing: border-box;
  &.flex {
    display: flex;
    align-items: center;
  }
  .top {
    border: 1px solid #00faf9;
    background-color: #205249;
    line-height: 24px;
    border-radius: 24px;
    text-align: center;
    width: 60px;
    margin: 0 auto;
  }
  .center {
    padding: 4px 0;
    box-sizing: border-box;
    text-align: center;
  }
  .btm {
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    &::before {
      content: '';
      display: inline-block;
      border-top: 26px solid transparent;
      border-left: 26px solid transparent;
      border-bottom: 26px solid #cd5359;
      border-right: 26px solid transparent;
    }
    .num {
      position: absolute;
      top: 31px;
      left: 22px;
      color: #fff;
    }
  }
}
</style>